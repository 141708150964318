/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style.css";
import listProduct from "./priceList.json";
import { useState } from "react";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [page, setPage] = useState("home");
  const [searchtext, setSearchtext] = useState("");
  const [cart, setCart] = useState([]);

  return (
    <>
      <Header isLogin={isLogin} setIsLogin={setIsLogin} />

      <SearchSection
        page={page}
        setPage={setPage}
        searchtext={searchtext}
        setSearchtext={setSearchtext}
      />

      <Main page={page} searchtext={searchtext} cart={cart} setCart={setCart} />

      <AddLine />

      <Footer />
    </>
  );
}

function Header({ isLogin, setIsLogin }) {
  return (
    <header className="header header-sticky">
      <div className="header-container container">
        <div className="header-company">
          <img
            className="logo"
            src="./img/skherb-logo.png"
            alt="SK Herb Logo"
          />
          <span>บริษัท เอสเค เฮิร์บ จำกัด</span>
        </div>
        <div className="header-menu"></div>
        <ul className="login-cart-list">
          {isLogin ? null : (
            <li>
              <a className="main-nav-link" id="login">
                Login
              </a>
            </li>
          )}
          {/* <li>
            <a className="main-nav-link nav-cta" id="cart">
              ตะกร้าสินค้า
            </a>
          </li> */}
        </ul>
      </div>
    </header>
  );
}

function SearchSection({ page, setPage, searchtext, setSearchtext }) {
  return (
    <section className="section-search search-sticky">
      <div className="container search-container">
        <MainNav page={page} setPage={setPage} />
        <SearchBar searchtext={searchtext} setSearchtext={setSearchtext} />
      </div>
    </section>
  );
}

function MainNav({ page, setPage }) {
  return (
    <nav className="main-nav">
      <ul className="main-nav-list">
        <li>
          <a className="main-nav-link" href="#" onClick={() => setPage("home")}>
            Home
          </a>
        </li>
        <li>
          <a
            className="main-nav-link"
            href="#"
            onClick={() => setPage("priceList")}
          >
            Price List
          </a>
        </li>
        <li>
          <a className="main-nav-link" href="#" onClick={() => setPage("cart")}>
            Cart
          </a>
        </li>
        <li>
          <a className="main-nav-link" href="#order">
            สั่งซื้อสินค้า
          </a>
        </li>
      </ul>
    </nav>
  );
}

function SearchBar({ searchtext, setSearchtext }) {
  function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <form className="searchbar" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Search ค้นหาสารสกัด"
        value={searchtext}
        onChange={(e) => setSearchtext(e.target.value)}
      />
      <button className="search-btn">🔎</button>
    </form>
  );
}

function Main({ page, searchtext, cart, setCart }) {
  console.log(page);
  switch (page) {
    case "priceList":
      return (
        <main className="main">
          <PriceList searchtext={searchtext} />
        </main>
      );
    case "cart":
      return (
        <main className="main">
          <Cart cart={cart} setCart={setCart} />
        </main>
      );
    default:
      return (
        <main className="main">
          <section className="section-listProduct">
            <div className="container">
              <ProductCardList
                searchtext={searchtext}
                cart={cart}
                setCart={setCart}
              />
            </div>
          </section>
        </main>
      );
  }
}

function searchfn(searchtext) {
  let data = [];
  if (searchtext === "") {
    data = listProduct;
  } else {
    // eslint-disable-next-line array-callback-return
    listProduct.map((el) => {
      if (
        el.nameThai.includes(searchtext) ||
        el.nameEng.includes(searchtext) ||
        el.INCI.includes(searchtext)
      )
        data.push(el);
    });
  }
  return data;
}

function PriceList({ searchtext }) {
  const data = searchfn(searchtext);
  return (
    <section className="priceList">
      <div className="container">
        <h3>รายการสินค้าทั้งหมด</h3>
        <h4>ราคายังไม่รวม vat 7% สั่งครบพันจัดส่งฟรี</h4>
        <div className="table-wrapper">
          <table className="card-list-table">
            <thead>
              <tr>
                <th className="table-head column-ID">ID</th>
                <th className="table-head column-momID">Code</th>
                <th className="table-head column-nameThai">สารสกัด</th>
                <th className="table-head column-INCI">INCI</th>
                <th className="table-head column-price100G">ขนาด 100G</th>
                <th className="table-head column-price450G">ขนาด 450G</th>
                <th className="table-head column-price1KG">ขนาด 1KG</th>
              </tr>
            </thead>
            <tbody>
              {data.map((product) => (
                <tr key={product.ID}>
                  <td className="table-column column-ID">{product.ID}</td>
                  <td className="table-column column-momID">{product.momID}</td>
                  <td className="table-column column-nameThai text-left">
                    {product.nameThai}
                  </td>
                  <td className="table-column column-INCI text-left">
                    {product.INCI}
                  </td>
                  <td className="table-column column-price100G">
                    {product.price100G} ฿
                  </td>
                  <td className="table-column column-price450G">
                    {product.price450G} ฿
                  </td>
                  <td className="table-column column-price1KG">
                    {product.price1KG} ฿
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

function Cart({ cart, setCart }) {
  let totalPrice = 0;
  let deliveryFee;
  cart.forEach((el) => {
    el.totalItemPrice = el.price * el.amount;
    totalPrice += el.totalItemPrice;
  });

  if (totalPrice < 1000 && cart.length > 0) {
    deliveryFee = 70;
    totalPrice += deliveryFee;
  }

  const vat = totalPrice * 0.07;
  const grandTotal = totalPrice + vat;

  return (
    <div className="cart">
      <div className="container">
        <div className="cartpage-grid">
          <div className="table-wrapper">
            <h3 className="text-center">กรุณาตรวจสอบรายการสินค้า</h3>
            <table className="card-list-table">
              <thead>
                <tr>
                  <th className="table-head">รหัสสินค้า</th>
                  <th className="table-head">ชื่อสินค้า</th>
                  <th className="table-head">ขนาด</th>
                  <th className="table-head">ราคาต่อหน่วย</th>
                  <th className="table-head">จำนวน</th>
                  <th className="table-head">ราคารวม</th>
                </tr>
              </thead>
              <tbody>
                {cart.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="table-column">
                      ไม่มีสินค้าในตะกร้า
                    </td>
                  </tr>
                ) : null}
                {cart.map((el) => {
                  return (
                    <tr>
                      <td className="table-column">{el.momID}</td>
                      <td className="table-column">{el.nameThai}</td>
                      <td className="table-column">{el.size}</td>
                      <td className="table-column">{el.price} บาท</td>
                      <td className="table-column">{el.amount} ชิ้น</td>
                      <td className="table-column text-right">
                        {el.totalItemPrice.toFixed(2)} บาท
                      </td>
                    </tr>
                  );
                })}
                {deliveryFee > 0 ? (
                  <tr>
                    <td colSpan="5" className="table-column">
                      ค่าขนส่ง (หมายเหตุ: สั่งครบ 1,000 บาท ส่งฟรี)
                    </td>
                    <td className="text-right">{deliveryFee.toFixed(2)} บาท</td>
                  </tr>
                ) : null}
                <tr>
                  <td colSpan="5" className="table-column">
                    ราคารวมก่อนภาษีมูลค่าเพิ่ม
                  </td>
                  <td className="text-right">{totalPrice.toFixed(2)} บาท</td>
                </tr>
                <tr>
                  <td colSpan="5" className="table-column">
                    ภาษีมูลค่าเพิ่ม (vat 7%)
                  </td>
                  <td className="text-right">{vat.toFixed(2)} บาท</td>
                </tr>
                <tr>
                  <td colSpan="5" className="table-column">
                    ราคารวมทั้งหมด
                  </td>
                  <td className="text-right">{grandTotal.toFixed(2)} บาท</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="checkout-div"></div>
        </div>
      </div>
    </div>
  );
}

function ProductCardList({ searchtext, cart, setCart }) {
  const data = searchfn(searchtext);

  return (
    <div className="grid product-grid">
      {data.map((product) => (
        <EachProduct
          product={product}
          key={product.ID}
          cart={cart}
          setCart={setCart}
        />
      ))}
    </div>
  );
}

function EachProduct({ product, cart, setCart }) {
  const [size, setSize] = useState("");

  function handleAddtocart(e) {
    e.preventDefault();

    let price;
    switch (size) {
      case "100G":
        price = product.price100G;
        break;
      case "450G":
        price = product.price450G;
        break;
      case "1KG":
        price = product.price1KG;
        break;
      default:
        break;
    }

    if (size !== "") {
      const currItem = {
        momID: product.momID,
        nameThai: product.nameThai,
        size,
        price,
      };

      let findItemInCart = false;
      for (let i = 0; i < cart.length; i++) {
        if (
          cart[i].momID === currItem.momID &&
          cart[i].nameThai === currItem.nameThai &&
          cart[i].size === currItem.size
        ) {
          cart[i].amount++;
          findItemInCart = true;
          break;
        }
      }

      if (!findItemInCart) {
        currItem.amount = 1;
        cart.push(currItem);
      }
    }
  }

  return (
    <div className="product-card">
      <img
        className="product-image"
        src={"./img/product-" + product.ID + ".jpg"}
        alt={"รูป" + product.nameThai}
      />
      <div className="product-attribute">
        <span className="product-title">{product.nameThai}</span>
        <span className="product-nameEng">{product.nameEng}</span>
        <span className="product-category">สำหรับเครื่องสำอาง</span>
        <form className="product-form" onSubmit={handleAddtocart}>
          <select
            className="product-size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          >
            <option value="">กรุณาเลือกขนาดสินค้า</option>
            <option value="100G">ขนาด 100G {product.price100G} บาท</option>
            <option value="450G">ขนาด 450G {product.price450G} บาท</option>
            <option value="1KG">ขนาด 1KG {product.price1KG} บาท</option>
          </select>
          <p className="vat-describe">ราคายังไม่รวม vat 7%</p>
          <button
            className="btn--add-to-cart"
            id={"addToCart-" + product.momID}
          >
            Add to cart
          </button>
        </form>
      </div>
    </div>
  );
}

function AddLine() {
  return (
    <section className="add-line-section" id="order">
      <div className="container">
        <div className="add-line">
          <img
            className="line-QR"
            src="./img/addLine.png"
            alt="Line QR code (SK Herb)"
          ></img>
          <p>
            ขออภัย ระบบสั่งซื้อสินค้าอยู่ระหว่างปรับปรุง กรุณาสั่งผ่าน
            <br />
            Line: @skherb
            <br />
            โทร 061-5698715, 089-9212901
          </p>
        </div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="footer-container container">
        <p>
          บริษัท เอสเค เฮิร์บ จำกัด <br />
          108/1 หมู่ 7 ถ.สวนหลวงร่วมใจ ต.สวนหลวง อ.กระทุ่มแบน จ.สมุทรสาคร 74110
          <br />
          โทร. 089-9212901, 061-5698715
          <br />
          E-mail: skherbcompany@gmail.com, s_u_m_e_t@hotmail.com
        </p>
      </div>
    </footer>
  );
}
export default App;
